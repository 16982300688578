<template>
	<v-card class="py-4">
		<v-skeleton-loader
			type="table-heading, divider, table-tbody, table-tfoot"
		/>
	</v-card>
</template>

<script>
export default {
	name: "TableSkeletonLoader"
}
</script>
